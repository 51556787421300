import React from "react"
import PropTypes from "prop-types"

import bannerImage from "../assets/placeholders/placeholder-01@2x.png"

import "../styles/tri-content.scss"

const TriContent = ({ image, content, title }) => (
  <section className="tri-content">
    <div className="section-title">{title}</div>
    <div className="columns">
      <div className="column">{content.left}</div>
      <div className="column">{content.right}</div>
      <div className="column image-container">
        <img width="500" src={bannerImage} alt="Art board" />
      </div>
    </div>
  </section>
)

TriContent.propTypes = {
  image: PropTypes.string,
  content: PropTypes.object,
  title: PropTypes.node,
}

TriContent.defaultProps = {
  image: null,
  content: {},
  title: null,
}

export default TriContent
