import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ImageBanner = ({ image }) => {
  return <Img className="bg-image" fluid={image} alt="" objectFit="cover" />
}

ImageBanner.propTypes = {
  image: PropTypes.object,
}

ImageBanner.defaultProps = {
  image: null,
}

export default ImageBanner
