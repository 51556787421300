import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import "../styles/main-banner.scss"

const MainBanner = ({ image, content, fromBottom, anchor }) => {
  return (
    <section className="main-banner" id={anchor}>
      <Img className="bg-image" fluid={image} alt="" objectFit="cover" />
      <div
        className={`banner-content columns ${
          fromBottom ? "from-bottom" : "from-top"
        }`}
      >
        <div className="info column is-two-fifths">{content}</div>
      </div>
    </section>
  )
}

MainBanner.propTypes = {
  image: PropTypes.object,
  content: PropTypes.node,
  fromBottom: PropTypes.bool,
  anchor: PropTypes.string,
}

MainBanner.defaultProps = {
  fromBottom: true,
  anchor: "",
}

export default MainBanner
