import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import MainBanner from "../../components/main-banner"
import ImageBanner from "../../components/image-banner"
import TriContent from "../../components/tri-content"
import ContactOptions from "../../components/contact-options"

import FonnorLogo from "../../assets/logos/logo-fonnor.svg"
import PlaceholderImage from "../../assets/placeholders/placeholder-01@2x.png"

const mainBannerContent = (
  <div>
    <img
      style={{ width: "10em", marginBottom: "2rem" }}
      src={FonnorLogo}
      alt="Fonnor Logo"
    />
    <p>
      Co-diseñamos con Proyecto MIJO talleres sobre la importancia del jaguar en
      el ecosistema para niñas y niños en comunidades del Occidente de México.
    </p>
  </div>
)

const infoBannerContent = {
  left: (
    <div>
      <p>
        El proyecto MIJO realiza el{" "}
        <span className="w-bold">Manejo Integrado</span> de hábitat del{" "}
        <span className="w-bold">Jaguar</span> a través de la participación
        comunitaria en el <span className="w-bold">Occidente</span> de México, y
        es una iniciativa de FONNOR.
      </p>
      <p>
        En colaboración con Azucena Mercado, co-diseñamos tres actividades para
        niñas y niños, donde pueden conocer más al jaguar y entender su
        importancia en nuestro ecosistema.
      </p>
      <p>
        Con esto en mente, nos embarcamos a una aventura al Sur de Jalisco. En
        total, realizamos tres talleres con FONNOR.
      </p>
    </div>
  ),
  right: (
    <div>
      <p>
        Primero viajamos a Autlán de Navarro, donde rugimos con más de 40 niñas,
        niños, jóvenes y adultos en la plaza principal del municipio.
      </p>
      <p>
        En Marzo del 2019, viajamos a Casimiro Castillo, también conocido como
        La Resolana, donde jugamos con 145 niñas y niños en 4 escuelas públicas.
      </p>
      <p>
        Finalmente, en Abril del 2019, colaboramos una vez más con el Proyecto
        MIJO para jugar y rugir con casi 500 niñas y niños en Papirolas.
      </p>
    </div>
  ),
}

const infoBannerTitle = (
  <>
    <h1 className="w-bold">Rugiendo como Jaguares</h1>
    <h2 className="w-normal">Proyecto MIJO, FONNOR</h2>
  </>
)

const FonnorPage = props => {
  const data = useStaticQuery(graphql`
    query FonnorQuery {
      fonnorImg: file(relativePath: { eq: "meiquer-fonnor.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fonnor2Img: file(relativePath: { eq: "meiquer-fonnor-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Fonnor" />
      <MainBanner
        image={data.fonnorImg.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <TriContent
        image={PlaceholderImage}
        content={infoBannerContent}
        title={infoBannerTitle}
      />
      <ImageBanner image={data.fonnor2Img.childImageSharp.fluid} />
      <ContactOptions />
    </Layout>
  )
}

export default FonnorPage
